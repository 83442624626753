import styled, { css } from 'styled-components';

import { sizing } from '@hultafors/shared/constants';

interface StyledContentBlockProps {
  $fullBleed?: boolean;
}

export const StyledContentBlock = styled.section<StyledContentBlockProps>`
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin-inline: auto;
  margin-block: ${sizing.large};

  ${({ $fullBleed }) =>
    $fullBleed
    && css`
      margin: 0;
      max-width: 100%;
    `}
`;
