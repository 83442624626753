import dynamic from 'next/dynamic';
import styled from 'styled-components';

const PlainButton = dynamic(() =>
  import('../plain-button/plain-button').then((mod) => mod.PlainButton),
);

export const ClearButtonStyled = styled(PlainButton)`
  width: var(--min-tap-size, 48px);
  height: var(--min-tap-size, 48px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-link, #000);

  &:hover {
    color: var(--color-link-hover, #333);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
