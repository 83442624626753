export const colors = {
  dimmed: '#eaeaea',
  black: '#000',
  text: '#111',
  white: '#fff',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  border: '#E5E5E5',
} as const;

export type Colors = keyof typeof colors;
