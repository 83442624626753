import styled from 'styled-components';

export const PictureStyled = styled.picture`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const PictureStyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
