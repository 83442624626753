import Markdown from 'markdown-to-jsx';
import styled, { css } from 'styled-components';

export const StyledMarkdown = styled(Markdown)`
  a {
    text-decoration: none;
    text-decoration: underline;
    color: #000;

    &:hover {
      color: #2222;
    }
  }

  ${({ theme }) =>
    theme
    && css`
      color: ${theme.colors.black.regular};

      &:hover {
        color: ${theme.colors.black.hover};
      }
    `}
`;
