import { StyledContentBlock } from './content-block.styled';

interface ContentBlockProps {
  children?: React.ReactNode;
  fullBleed?: boolean;
  className?: string;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  className,
  children,
  fullBleed,
}) => {
  return (
    <StyledContentBlock className={className} $fullBleed={fullBleed}>
      {children}
    </StyledContentBlock>
  );
};
