import TrashSvg from '../../svg/trash.svg';

import { ClearButtonStyled } from './clear-button.styled';

type ClearButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const ClearButton: React.FC<ClearButtonProps> = ({
  title,
  children,
  ...rest
}) => {
  if (!children) {
    return null;
  }
  return (
    <ClearButtonStyled aria-label={title} title={title} {...rest}>
      {children ?? (
        <TrashSvg aria-hidden={true} focusable={false} width={16} height={16} />
      )}
    </ClearButtonStyled>
  );
};
