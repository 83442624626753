import styled, { css, CSSProperties } from 'styled-components';

interface StyledAspectRatioImageProps {
  $aspectRatio: CSSProperties['aspectRatio'];
  $force?: boolean;
  $breakpointMax?: string;
  $breakpointMin?: string;
}

const ImageSharedStyle = css`
  width: 100%;
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const MobileContainer = styled.div<StyledAspectRatioImageProps>`
  ${ImageSharedStyle}
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};

  @media screen and (min-width: ${({ $breakpointMax }) => $breakpointMax}) {
    display: none;
  }
`;

export const TabletContainer = styled.div<StyledAspectRatioImageProps>`
  ${ImageSharedStyle}
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  display: none;

  @media screen and (min-width: ${({ $breakpointMin }) => $breakpointMin}) {
    display: block;
  }

  @media screen and (min-width: ${({ $breakpointMax }) => $breakpointMax}) {
    display: none;
  }
`;

export const DesktopContainer = styled.div<StyledAspectRatioImageProps>`
  ${ImageSharedStyle}
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  ${({ $force, $breakpointMin }) =>
    !$force
    && css`
      display: none;

      @media screen and (min-width: ${$breakpointMin}) {
        display: block;
      }
    `}
`;
