import { useEffect, useRef } from 'react';

import { AnimatePresence, AnimationProps } from 'framer-motion';
import { useRouter } from 'next/router';
import { Portal } from 'react-portal';
import { useClickAway, useKey, useLockBodyScroll } from 'react-use';

import { Backdrop, PopupContainer } from './popup.styled';

export interface PopupProps {
  children?: React.ReactNode;
  container?: Element | null | string;
  toggle(force: boolean): void;
  isOpen: boolean;
  duration?: number;
  enterFrom: 'top' | 'bottom' | 'left' | 'right';
}

export const Popup: React.FC<PopupProps> = ({
  children,
  container = '#portal',
  toggle,
  isOpen,
  enterFrom,
  duration = 0.1,
}) => {
  const nodeRef = useRef<Element | null | undefined>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  function close() {
    toggle(false);
  }

  // Lock body if popup is open
  useLockBodyScroll(isOpen);

  // Close popup if user clicks away or pressed escape
  useClickAway(innerRef, (event) => {
    event.preventDefault();
    close();
  });

  useKey('Escape', (event) => {
    event.preventDefault();

    close();
  });

  // Close popup on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', close);
    return () => {
      router.events.off('routeChangeComplete', close);
    };
  }, [router.events]);

  useEffect(() => {
    if (container && typeof container !== 'string') {
      nodeRef.current = container;
    }
    if (typeof container === 'string') {
      nodeRef.current = document.querySelector(container);
    }
  }, []);

  /*
  Set the direction from which the popup
  will enter the screen.
  */
  let popupTransform = 'translate(-50%, -47%)';

  if (enterFrom === 'top') {
    popupTransform = 'translate(-50%, -53%)';
  }
  if (enterFrom === 'left') {
    popupTransform = 'translate(-53%, -50%)';
  }
  if (enterFrom === 'right') {
    popupTransform = 'translate(-47%, -50%)';
  }

  // Backdrop animation properties
  const backdrop: AnimationProps = {
    initial: {
      backdropFilter: 'blur(0px) brightness(100%)',
      WebkitBackdropFilter: 'blur(0px) brightness(100%)',
    },
    animate: {
      backdropFilter: 'blur(3px) brightness(75%)',
      WebkitBackdropFilter: 'blur(3px) brightness(75%)',
      transition: { duration, ease: 'linear' },
    },
    exit: {
      backdropFilter: 'blur(0px) brightness(100%)',
      WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      transition: { duration, ease: 'linear' },
    },
  };

  // Popup animation properties
  const popup: AnimationProps = {
    initial: {
      opacity: 0,
      transform: popupTransform,
    },
    animate: {
      opacity: 1,
      transition: { duration: duration, ease: 'linear' },
      transform: 'translate(-50%, -50%)',
    },
    exit: {
      opacity: 0,
      transition: { duration, ease: 'linear' },
      transform: popupTransform,
    },
  };

  if (!nodeRef.current) {
    return null;
  }

  return (
    <Portal node={nodeRef.current}>
      <AnimatePresence>
        {isOpen && (
          <Backdrop
            initial={backdrop.initial}
            animate={backdrop.animate}
            exit={backdrop.exit}
          >
            <PopupContainer
              ref={innerRef}
              initial={popup.initial}
              animate={popup.animate}
              exit={popup.exit}
            >
              {children}
            </PopupContainer>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};
