import type { Thing, WithContext } from 'schema-dts';

interface MicroDataProps {
  data?: WithContext<Thing> | null;
}

/**
 * Renders provided schema.org data as JSON-LD script tag.
 */
export const MicroData: React.FC<MicroDataProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <script
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      type="application/ld+json"
    />
  );
};
