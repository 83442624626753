import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';
import { ImageFragment } from '@hultafors/shared/types';

import {
  DesktopContainer,
  ImageContainer,
  MobileContainer,
  TabletContainer,
} from './aspect-ratio-image.styled';

interface AspectRatioImageProps {
  // Desktop is default if no breakpoints needed
  mobileImage?: ImageFragment | null;
  tabletImage?: ImageFragment | null;
  desktopImage?: ImageFragment | null;
  priority?: boolean;
  alt?: string;
}

export const AspectRatioImage: React.FC<AspectRatioImageProps> = ({
  desktopImage,
  tabletImage,
  mobileImage,
  priority,
  alt = '',
}) => {
  return (
    <ImageContainer>
      <>
        {desktopImage?.responsiveImage?.src && (
          <DesktopContainer
            $aspectRatio={desktopImage?.responsiveImage?.aspectRatio}
            $force={
              !mobileImage?.responsiveImage && !tabletImage?.responsiveImage
            }
            $breakpointMin={breakpoints.desktop}
          >
            <Image
              src={desktopImage?.responsiveImage?.src}
              alt={desktopImage?.alt || alt || ''}
              fill
              sizes="100vw"
              priority={priority}
              placeholder="blur"
              blurDataURL={desktopImage?.responsiveImage?.base64 || ''}
              style={{ objectFit: 'contain' }}
            />
          </DesktopContainer>
        )}
        {tabletImage?.responsiveImage?.src && (
          <TabletContainer
            $aspectRatio={tabletImage.responsiveImage.aspectRatio}
            $breakpointMin={breakpoints.tablet}
            $breakpointMax={breakpoints.desktop}
          >
            <Image
              src={tabletImage?.responsiveImage?.src}
              alt={tabletImage?.alt || alt || ''}
              fill
              sizes="100vw"
              priority={priority}
              placeholder="blur"
              blurDataURL={desktopImage?.responsiveImage?.base64 || ''}
              style={{ objectFit: 'contain' }}
            />
          </TabletContainer>
        )}

        {mobileImage?.responsiveImage?.src && (
          <MobileContainer
            $aspectRatio={mobileImage.responsiveImage.aspectRatio}
            $breakpointMax={
              tabletImage ? breakpoints.tablet : breakpoints.mobileMax
            }
          >
            <Image
              src={mobileImage?.responsiveImage?.src}
              alt={mobileImage?.alt || alt || ''}
              fill
              sizes="100vw"
              priority={priority}
              placeholder="blur"
              blurDataURL={mobileImage?.responsiveImage?.base64 || ''}
              style={{ objectFit: 'contain' }}
            />
          </MobileContainer>
        )}
      </>
    </ImageContainer>
  );
};
