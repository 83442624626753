import styled from 'styled-components';

export const SquareStyled = styled.div`
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:empty {
    display: none;
  }
`;
