import { ReactNode } from 'react';

import styled from 'styled-components';

export interface PlainButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

const StyledPlainButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
`;

export const PlainButton: React.FC<PlainButtonProps> = ({
  children,
  ...rest
}) => {
  return <StyledPlainButton {...rest}>{children}</StyledPlainButton>;
};

export default PlainButton;
