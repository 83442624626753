import { ResponsiveImageType } from 'react-datocms';

import { PictureStyled, PictureStyledImg } from './picture.styled';

interface PictureSourceImage {
  params: Record<string, string>;
  descriptor?: string;
}

interface PictureSource {
  media?: string;
  images: PictureSourceImage[];
}

export interface PictureProps {
  url: string;
  base64?: ResponsiveImageType['base64'];
  bgColor?: ResponsiveImageType['bgColor'];
  alt: string;
  sizes?: string;
  params?: Record<string, string>;
  sources: PictureSource[];
  className?: string;
  classNameImg?: string;
}

export const Picture: React.FC<PictureProps> = ({
  url,
  base64,
  alt = '',
  params = {},
  sources = [],
  className,
  classNameImg,
}) => {
  const [baseUrl, originalParams = ''] = url.split('?');
  const baseParams = new URLSearchParams(originalParams);
  baseParams.set('auto', 'format');
  baseParams.set('q', '85');
  baseParams.delete('w');
  baseParams.delete('h');
  for (const key in params) {
    baseParams.set(key, params[key]);
  }

  const src = `${baseUrl}?${baseParams.toString()}`;
  const sourceMapper = (props: PictureSource, index: number) => {
    const { media, images } = props;
    const srcSet = images
      .map(({ params, descriptor = '' }) => {
        const localParams = new URLSearchParams(baseParams);
        for (const key in params) {
          localParams.set(key, params[key]);
        }
        const query = localParams.toString();
        return `${baseUrl}?${query} ${descriptor}`.trim();
      })
      .join(', ');
    return (
      <source
        {...(media ? { media } : {})}
        srcSet={srcSet}
        key={`${srcSet}-${index}`}
      />
    );
  };

  const style: React.CSSProperties = {};
  if (base64) {
    style.backgroundImage = `url(${base64})`;
  }

  return (
    <PictureStyled className={className} style={style}>
      {sources.map(sourceMapper)}
      <PictureStyledImg
        src={src}
        alt={alt}
        className={classNameImg}
        loading="lazy"
      />
    </PictureStyled>
  );
};
