import { m } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const Backdrop = styled(m.div)`
  position: fixed;
  z-index: 20;
  inset: 0;
  transform: translate3d(0, 0, 0);
  display: none;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: block;
  }
`;

export const PopupContainer = styled(m.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  filter: drop-shadow(10px 10px 20px rgb(0 0 0 / 15%));
`;
