import Link from 'next/link';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/shared/constants';

/* import chevronSvg from '../../svg/chevron.svg'; */
/* import plusSvg from '../../svg/plus.svg'; */

interface MenuProps {
  $menu?: boolean;
}

const AccordionLinkShared = css<MenuProps>`
  display: flex;
  align-items: center;
  border-block-start: thin solid ${colors.dimmed};
  border-block-start: ${({ $menu }) =>
    $menu ? '0' : `thin solid ${colors.dimmed}`};
  width: 100%;
  padding-block: ${({ $menu }) => ($menu ? '8px' : '24px')};
  min-height: 44px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(Link)<MenuProps>`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)`
  position: relative;
  width: 100%;
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  width: 100%;
`;

export const ButtonInner = styled.span<MenuProps>`
  position: relative;
  ${AccordionLinkShared};
  min-height: 32px;
`;

interface PlusProps {
  $plus?: boolean;
}

export const AccordionItemButtonStyled = styled(AccordionItemButton)<PlusProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }
`;

export const Indicator = styled.svg<PlusProps>`
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  transition: all 0.1s ease-out;

  [aria-expanded='true'] & {
    transform: rotate(${({ $plus }) => ($plus ? '45deg' : '-180deg')});
    transform-origin: 8px 8px;
  }
`;

interface HeightProps {
  $height?: number;
}

export const AccordionItemPanelStyled = styled(AccordionItemPanel)<HeightProps>`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;

export const AccordionItemContent = styled.div`
  padding-block: 8px;
`;
