import { useMemo } from 'react';

import dynamic from 'next/dynamic';

import { LanguageData } from '@hultafors/shared/types';

import { List } from './market-picker.styled';

const MarketPickerOption = dynamic(() =>
  import('../market-picker-option/market-picker-option').then(
    (mod) => mod.MarketPickerOption,
  ),
);
const SlideOut = dynamic(() =>
  import('../slide-out/slide-out').then((mod) => mod.SlideOut),
);
const SlideOutHeader = dynamic(() =>
  import('../slide-out-header/slide-out-header').then(
    (mod) => mod.SlideOutHeader,
  ),
);

interface MarketPickerProps {
  label: string | null | undefined;
  markets: LanguageData[];
  isOpen: boolean;
  toggle(force?: boolean): void;
  active?: string;
  currentLocale?: string;
}

function marketFilter(market: LanguageData) {
  return market.name && market.url;
}

export const MarketPicker: React.FC<MarketPickerProps> = ({
  label,
  markets = [],
  isOpen = false,
  toggle,
  active = '',
  currentLocale,
}) => {
  const filteredMarkets = useMemo(() => {
    return markets?.filter(marketFilter) || [];
  }, [markets]);
  if (!filteredMarkets.length) {
    return null;
  }
  function marketMapper(market: LanguageData) {
    if (!market.url) {
      return null;
    }
    const marketUrl = new URL(market.url);
    const marketHostnamePath = [
      marketUrl.hostname,
      marketUrl.pathname.replace(/^\//, '').replace(/\/$/, ''),
    ]
      .filter(Boolean)
      .join('/');
    return (
      <MarketPickerOption
        key={`Market-${market.name}`}
        market={market}
        active={marketHostnamePath === active}
        currentLocale={currentLocale}
      />
    );
  }
  const onClick = () => {
    toggle(false);
  };
  const header = (
    <SlideOutHeader onClick={onClick} closeLabel="close">
      {label}
    </SlideOutHeader>
  );
  return (
    <SlideOut
      isOpen={isOpen}
      toggle={toggle}
      header={header}
      key="marketpicker"
    >
      <List>{filteredMarkets.map(marketMapper)}</List>
    </SlideOut>
  );
};
