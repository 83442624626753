import styled from 'styled-components';

export const Details = styled.details`
  padding: 0;
  display: block;
  position: relative;
`;
export const Summary = styled.summary`
  display: block;
  padding: 0;
  cursor: pointer;
`;

export const Content = styled.nav`
  z-index: 1;
  background-color: white;
  padding: 1rem;
  display: block;
  overflow-y: auto;
  position: absolute;
  max-height: calc(100vh - 70px);

  /* width: 200px; */
  top: 42px;
  right: 0;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
