import styled from 'styled-components';

export const StyledSkeleton = styled.div`
  background: linear-gradient(315deg, rgb(243 246 247), rgb(216 224 228));
  background-size: 400% 400%;
  animation: skeleton 2s ease-in-out infinite;

  /* box-shadow: inset 0 0 3rem rgba(140, 150, 157, 0.1); */

  @keyframes skeleton {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
`;
