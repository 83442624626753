import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const InfoWindow = styled.div``;

interface MapContainerProps {
  $sticky?: boolean;
}

export const MapContainer = styled.div<MapContainerProps>`
  ${({ $sticky }) =>
    $sticky
      ? css`
          position: sticky;
          top: calc(var(--header-height, 0) + var(--map-offset, 0));
        `
      : css`
          position: relative;
        `};
  width: var(--map-width);
  height: var(--map-height);

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    height: var(--map-height-desktop);
  }
`;

export const InfoLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  vertical-align: middle;
  text-decoration-skip-ink: auto;
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

export const Title = styled.p`
  font-weight: bold;

  &:empty {
    display: none;
  }
`;

export const Address = styled.p`
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }

  span {
    &:empty {
      display: none;
    }
  }
`;

export const Website = styled.p`
  margin-top: 1rem;

  &:empty {
    display: none;
  }
`;

export const MapLink = styled.p`
  margin-top: 1rem;

  &:empty {
    display: none;
  }
`;
