import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

interface GridChildColumnSpan {
  columns?: number;
  breakpoint?: Breakpoint;
  columnGap?: number;
  start?: number;
  display?: CSSProperties['display'];
}

export type GridChildColumnSpanProp = number | GridChildColumnSpan[];

export interface GridChildStyledProps {
  $columnSpan?: GridChildColumnSpanProp;
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowSpan?: string;
  $rowStart?: string;
}

function getColumnSpan({ $columnSpan }: GridChildStyledProps) {
  const isObject = typeof $columnSpan === 'object';
  if (isObject) {
    return $columnSpan.map(({ breakpoint, start, columns, display }) => {
      const startValue = start ? `${start} /` : '';
      const end = columns ? `span ${columns}` : -1;
      const style = css`
        grid-column: ${startValue} ${end};
        display: ${display ?? 'initial'};
      `;
      if (!breakpoint) {
        return style;
      }
      return css`
        @media screen and (min-width: ${breakpoints[breakpoint]}) {
          ${style};
        }
      `;
    });
  }
  return css`
    grid-column: ${$columnSpan ? $columnSpan : '1 / -1'};
  `;
}
export const GridChildStyled = styled.div<GridChildStyledProps>`
  grid-row: ${({ $rowStart = '' }) => `${$rowStart}`} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};
  ${getColumnSpan};
  place-self: ${({ $alignSelf }) => $alignSelf} ${({ $align }) => $align};
  padding: ${({ $padding }) => $padding};
`;
