import { useEffect } from 'react';

export interface BynderVideoProps {
  videoId: string;
  style?: React.CSSProperties;
}

export const BynderVideo: React.FC<BynderVideoProps> = ({ style, videoId }) => {
  useEffect(() => {
    const scriptId = 'bynder-widgets-js';
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src
      = 'https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/latest/bynder-embed.js';
    script.setAttribute('data-account-url', 'hultaforsgroup.bynder.com');
    script.setAttribute('data-language', 'en');
    script.async = true;
    script.onload = () => {
      console.info('Bynder script loaded');
    };

    document.body.appendChild(script);

    return () => {
      if (script) {
        script.remove();
      }
    };
  }, [videoId]);

  if (!videoId) {
    return null;
  }

  return (
    <div
      data-bynder-widget="video-item"
      data-media-id={videoId}
      data-width=""
      className="bynder-video"
      style={style}
    >
    </div>
  );
};
