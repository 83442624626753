import styled from 'styled-components';

export const StyledAzureVideo = styled.div`
  .vjs-big-play-button,
  .amp-flush-skin:hover .vjs-big-play-button {
    left: calc(50% - 0.05em);
    top: calc(50% - 0.05em);
    border-radius: 100%;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .amp-content-title {
    .logo-title-row {
      .amp-logo {
        display: none;
      }
    }
  }

  .azuremediaplayer {
    aspect-ratio: 16 / 9;
  }
`;
