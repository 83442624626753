import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { GridSpacing, gridSpacing } from './grid';

export type GridColumnGapProp = GridColumnGap[] | GridSpacing | number;
export type GridColumnsProp =
  | GridColumnGap[]
  | number
  | 'auto-fit'
  | 'auto-fill';

interface GridColumnGap {
  columns?: number;
  breakpoint?: Breakpoint;
  columnGap?: number;
  start?: number;
  display?: CSSProperties['display'];
}

export type GridRowGapProp = GridSpacing | number;

interface GridStyledProps {
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $columnGap?: GridColumnGapProp;
  $columns?: GridColumnsProp;
  $minColumnWidth?: string;
  $rowGap?: GridRowGapProp;
  $autoFlow?: CSSProperties['gridAutoFlow'];
}

export const GridStyled = styled.div<GridStyledProps>`
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  display: grid;
  grid-auto-flow: ${({ $autoFlow }) => $autoFlow || 'row'};
  justify-content: ${({ $center }) => ($center ? 'center' : 'inherit')};
  margin: ${({ $margin }) => $margin};
  max-width: ${({ $maxWidth }) => $maxWidth};
  ${({ $columnGap }) => {
    if (typeof $columnGap === 'object') {
      return $columnGap.map(({ breakpoint, columnGap }) => {
        if (!breakpoint) {
          return css`
            grid-column-gap: ${columnGap}px;
          `;
        } else {
          return css`
            @media screen and (min-width: ${breakpoints[breakpoint]}) {
              grid-column-gap: ${columnGap}px;
            }
          `;
        }
      });
    } else if (typeof $columnGap === 'number') {
      return `grid-column-gap: ${$columnGap}px;`;
    } else if ($columnGap) {
      return `grid-column-gap: ${gridSpacing[$columnGap]}px;`;
    } else {
      return '';
    }
  }};
  ${({ $columns, $minColumnWidth }) => {
    if (typeof $columns === 'object') {
      return $columns.map(({ breakpoint, columns }) => {
        if (!breakpoint) {
          return css`
            grid-template-columns: repeat(${columns}, minmax(0, 1fr));
          `;
        } else {
          return css`
            @media screen and (min-width: ${breakpoints[breakpoint]}) {
              grid-template-columns: repeat(${columns}, minmax(0, 1fr));
            }
          `;
        }
      });
    } else {
      return css`
        grid-template-columns: repeat(
          ${$columns},
          ${$minColumnWidth
            ? `minmax(${$minColumnWidth}, 1fr)`
            : 'minmax(0, 1fr)'}
        );
      `;
    }
  }};
  row-gap: ${({ $rowGap }) =>
    typeof $rowGap === 'number'
      ? `${$rowGap}px`
      : $rowGap
      ? gridSpacing[$rowGap]
      : ''};
`;
